@import "../../App.css";

.nbadge {
  cursor: default;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 13px;
  text-align: center;
  text-transform: capitalize;
  padding: 2px 4px;
}

.customBG {
  background: var(--secondary-color);
}

.lightBG {
  background: var(--color-surface);
}

.orangeBG {
  background: var(--color-orange);
}

.grayBG {
  background: var(--color-gray);
}

.redBG {
  background: var(--brand-color);
}

.aquaBG {
  background: var(--color-aqua);
}

.darkFont {
  color: var(--color-text-primary);
}

.darkFont2 {
  color: var(--color-text-secondary);
}

.lightFont {
  color: var(--color-white);
}

.boldText {
  font-weight: bold;
}

.redOriginalLightBG {
  background: #ffecf2;
}
.redFont {
  color: #ff4880;
}

.greenOriginalLightBG {
  background: #e5f8f4;
}

.greenFont {
  color: #0dbd92;
}

.yellowFont {
  color: #e0bc0b;
}

.greenBase {
  background: #3ac8ac;
}

.greenBase {
  background: #3ac8ac;
}

.yellowBase {
  background: #fff2dd;
}

.invalidGrey {
  background: linear-gradient(0deg, #edf1f7, #edf1f7), #ffffff;
}

.textGrey {
  color: #989da5;
}
