@import "../../App.css";

.blockContainer {
  background: var(--color-aqua);
  border-radius: 4px;
  border: 1px solid var(--color-gray2);
  display: flex;
  height: 63px;
  justify-content: center;
  padding: 16px 20px;
}
