.badgeFilter {
  background-color: #7c8a99;
  border-radius: 16px;
  color: #fff;
  cursor: default;
  margin-right: 4px;
  text-transform: capitalize;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: var(--font-size-small);
  margin-top: 5px;
}

.badgeIcon {
  font-size: var(--font-size-caption);
  cursor: pointer;
}
