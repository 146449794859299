@import "../../App.css";

.callingActionBlock {
  background: var(--color-white);
  border: 0;
}

.callingActionBlock p {
  display: inline;
  font-size: 13px;
  font-weight: normal;
}

.callingSaveButton {
  /* float: right; */
  /* font-size: 14px; */
  /* padding: 8px 28px; */
  margin-bottom: 15px;
}

.callingActionHeader {
  border-bottom: 2px solid var(--color-aqua);
  border-top: 2px solid var(--color-aqua);
  padding: 8px 0;
}

.content .callResultHeader {
  font-weight: bold;
  font-size: var(--font-size-md);
}

.callingActionBlock .commentsBlock {
  background: var(--color-aqua);
  border-radius: 6px;
  padding: 12px;
}

.commentsBlock {
  background: var(--color-aqua);
  border-radius: 6px;
  padding: 12px;
}
