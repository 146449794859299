@import "../../App.css";

.customBadge {
  background: var(--color-surface);
  border: 1px solid var(--color-dark-aqua);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 13px;
  text-align: center;
  text-transform: capitalize;
  padding: 2px 4px;
}

.active {
  background: var(--color-gray);
  color: var(--color-white);
  cursor: pointer;
}
