.breadcrumb {
  color: var(--color-gray);
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-md);
  line-height: 140%;
}

.breadcrumb a:any-link {
  color: var(--color-gray);
}

.breadcrumb .reportTitle {
  color: #2a2b2e;
  font-size: var(--font-size-md);
  line-height: 140%;
}
