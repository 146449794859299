.errorContainerBlock {
  display: table;
  width:90vw;
  height: 90vh;
  margin: auto;
}

.errorContainerBlock .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.errorContainerBlock .imgNotFound {
  max-width: 500px;
}

.errorContainerBlock .imgUnauthorized {
  max-width: 450px;
}
