:root {
  --brand-color: #cc0200;
  --color-surface: #f8f9fc;
  --color-text-primary: #455362;
  --color-text-secondary: #7c8a99;
  --color-bg-primary: #cc0200;
  --color-bg-secundary: #f8f9fc;
  --color-orange: #ff8b00;
  --color-gray: #7c8a99;
  --color-gray2: #bec5cc;
  --color-white: #ffffff;
  --color-aqua: #edf4fc;
  --color-dark-aqua: #dee0e7;
  --call-in-action: #cc0200;
  --secondary-color: #cc0200;
  --validation-color: #455362;
  --color-btn-active: #a60200;
  --color-background: #f8f9fc;
  --brand-color-secundary: #cc0200;
  --brand-color-hover: #cc0200;
  --sidebar-width: -280px;
  --color-btn-hover: #a60200;
  --call-in-action-second: #cc0200;
  --color-btn-second: #a60200;
  --color-background-secundary: #f8fbff;
  --font-size-caption: 15px; /* 12px */
  --font-size-small: 12px;
  --font-size-md: 15px; /* 11px */
  --font-size-lg: 17px; /* 16px */
  --call-in-action-danger: #c54646;
  --color-btn-danger-hover: #c54646;
  --font-size-button: 14px;
  --color-background-content: #f6fafe; /*  #edf1f7 */
}
.font-size-caption {
  font-size: var(--font-size-caption);
}

body {
  font-family: "Nova", sans-serif !important;
  font-size: var(--font-size-caption);
  /* font-weight: 500; */
}

input[type="text"]:focus,
select:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none;
  border-color: #ced4da;
  outline: none;
}

/* a {
  color: var(--secondary-color) !important;
} */

.global_badgeFilter {
  background-color: #7c8a99;
  border-radius: 16px;
  color: #fff;
  cursor: default;
  margin-right: 4px;
  text-transform: capitalize;
}

.icons_color {
  color: var(--secondary-color);
}

.btn_bg_color {
  background: var(--call-in-action);
  border: 1px solid var(--call-in-action);
}

.page-link {
  color: var(--color-text-primary);
}

.page-item.active .page-link {
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
}

.form-control:focus {
  border-color: #ced4da;
}

.validation_color {
  color: var(--validation-color);
}

.secondary_color {
  color: var(--secondary-color);
}

.callinaction_bg_color {
  background-color: var(--call-in-action) !important;
  border-color: var(--call-in-action) !important;
}

.callinaction_bg_color:hover:not(:disabled) {
  background-color: var(--call-in-action) !important;
  border-color: var(--call-in-action) !important;
  filter: brightness(85%);
}

.nav-tabs .nav-link {
  color: var(--secondary-color);
}

.btn-primary .btn-primary.disabled {
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
}
.btn.active {
  background-color: var(--color-btn-active) !important;
  border-color: var(--color-btn-active) !important;
}
.dropdown-item.active {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}
.dropdown-item:active {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: white !important;
}

.btn-primary {
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:focus .focus {
  color: white;
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
  /* box-shadow: 0 0 0 0.2rem var(--call-in-action); */
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: white;
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
}

.capitalize-text {
  text-transform: capitalize;
}

.dropdown-item:active span {
  color: #f8f9fc !important;
}
.dropdown-item:active svg {
  color: #f8f9fc !important;
}

#content {
  background-color: var(--color-background-content) !important;
  padding-left: 0px;
  padding-right: 0px;
}
@media only screen and (min-width: 770px) {
  #content {
    background-color: var(--color-background-content) !important;
    padding-left: 8px;
    padding-right: 8px;
  }
}

html,
.App,
.wrapper,
#content {
  min-height: 100vh;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.app {
  display: flex;
  position: relative;
  background-color: var(--color-background-content);
}

.wrapper {
  width: calc(100% - 255px);
  background: var(--color-background-content);
  transition: width 0.3s;
}
.wrapperCloseSider {
  width: calc(100% - 95px);
  background: var(--color-background-content);
  transition: width 0.3s;
}
@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
    background: var(--color-background-content);
  }
  .wrapperCloseSider {
    width: 100%;
    background: var(--color-background-content);
  }
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./fonts/Gotham/Gotham-Book.otf) format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./fonts/Gotham/Gotham-Medium.otf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./fonts/Gotham/Gotham-Bold.otf) format("truetype");
  font-weight: bold;
}
/* Nova */
@font-face {
  font-family: "Nova";
  src: local("Nova"),
    url("./fonts/Nova/Mark Simonson - Proxima Nova Regular.otf")
      format("truetype");
}

@font-face {
  font-family: "Nova";
  src: local("Nova"),
    url("./fonts/Nova/Mark Simonson - Proxima Nova Semibold.otf")
      format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Nova";
  src: local("Nova"),
    url("./fonts/Nova/Mark Simonson - Proxima Nova Bold.otf") format("truetype");
  font-weight: bold;
}

.fontGotham {
  font-family: "Gotham";
  font-weight: "bold";
}

.fontNova {
  font-family: "Nova";
  font-weight: "bold";
}

@media only screen and (max-width: 768px) {
  .pro-sidebar.md {
    position: fixed;
    left: -280px !important;
  }
  .pro-sidebar.md.toggled {
    left: 0 !important;
  }
}

.colorTextSecondary {
  color: var(--secondary-color);
}

.modal-size {
  height: 446px;
  max-width: 682px;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.colorTextSecondaryRed {
  color: #e23232;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.8;
  background: var(--color-dark-aqua);
}

.pro-sidebar {
  background: transparent !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--call-in-action);
  background-color: var(--call-in-action);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--call-in-action);
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--call-in-action);
}

/* Custom Toastify success */
.Toastify__toast--success {
  background: #e5f7ef !important;
  border: 1px solid #49ce2a;
  color: #149911 !important;
  font-size: 14px;
  padding: 0px !important;
  border-radius: 4px !important;
}

/* .Toastify__toast--success::before {
  content: "";
  width: 6px;
  background: #bae3c1 !important;
  margin-right: 6px;
} */

.Toastify__toast--success > .Toastify__toast-body {
  width: 100%;
  padding-left: 24px;
}
/* 
.Toastify__toast--success > .Toastify__toast-body .Toastify__toast-icon {
  padding: 8px;
  background: #b9e0bf;
  width: 32px;
  height: 32px;
  font-size: 11px;
  border-radius: 50%;
} */

.Toastify__toast--success > .Toastify__toast-body::before {
  width: 100%;
}

.Toastify__close-button--success > svg {
  fill: #3eab79 !important;
}

.Toastify__close-button--light {
  margin: auto 0;
  padding-right: 15px !important;
}

.toast-blank-success {
  background-color: white !important;
  border: 0px;
  color: #132b40 !important;
}

.toast-blank-success::before {
  content: "";
  width: 6px;
  background: #08997c !important;
  margin-right: 6px;
}

/* Custom Toastify error */
.Toastify__toast--error {
  background: #fff2f1 !important;
  border: 1px solid #f09490;
  color: #e95f59 !important;
  font-size: 14px;
  padding: 0px !important;
  border-radius: 4px !important;
}

/* .Toastify__toast--error::before {
  content: "";
  width: 6px;
  background: #fbd5d3 !important;
  margin-right: 6px;
} */

.Toastify__toast--error > .Toastify__toast-body {
  width: 100%;
  padding-left: 24px;
}

/* .Toastify__toast--error > .Toastify__toast-body .Toastify__toast-icon {
  padding: 8px;
  background: #fbd5d3;
  width: 32px;
  height: 32px;
  font-size: 11px;
  border-radius: 50%;
} */

.Toastify__toast--error > .Toastify__toast-body::before {
  width: 100%;
}

.Toastify__close-button--error > svg {
  fill: #e95f59 !important;
}

.Toastify__close-button--error {
  margin: auto 0;
  padding-right: 15px !important;
}

.toast-blank-error {
  background-color: white !important;
  border: 0px;
  color: #132b40 !important;
}

.toast-blank-error::before {
  content: "";
  width: 6px;
  background: #930909 !important;
  margin-right: 6px;
}

/* Custom Toastify Warning */
.Toastify__toast--warning {
  background: #fff5e0 !important;
  border: 1px solid #f1d189;
  color: #e2a212 !important;
  font-size: 14px;
  padding: 0px !important;
  border-radius: 4px !important;
}

/* .Toastify__toast--warning::before {
  content: "";
  width: 6px;
  background: #fae3b8 !important;
  margin-right: 6px;
} */

.Toastify__toast--warning > .Toastify__toast-body {
  width: 100%;
  padding-left: 24px;
}

/* .Toastify__toast--warning > .Toastify__toast-body .Toastify__toast-icon {
  padding: 8px;
  background: #fae4b7;
  width: 32px;
  height: 32px;
  font-size: 11px;
  border-radius: 50%;
} */

.Toastify__toast--warning > .Toastify__toast-body::before {
  width: 100%;
}

.Toastify__close-button--warning > svg {
  fill: #e2a212 !important;
}

.Toastify__close-button--warning {
  margin: auto 0;
  padding-right: 15px !important;
}

.toast-blank-warning {
  background-color: white !important;
  border: 0px;
  color: #132b40 !important;
}

.toast-blank-warning::before {
  content: "";
  width: 6px;
  background: #b2840d !important;
  margin-right: 6px;
}

/* Custom Toastify Info */
.Toastify__toast--info {
  background: #e4e7fb !important;
  border: 1px solid #5967e9;
  color: #5967e9 !important;
  font-size: 14px;
  padding: 0px !important;
  border-radius: 4px !important;
}

/* .Toastify__toast--info::before {
  content: "";
  width: 6px;
  background: #c2c7f7 !important;
  margin-right: 6px;
} */

.Toastify__toast--info > .Toastify__toast-body {
  width: 100%;
  padding-left: 24px;
}

/* .Toastify__toast--info > .Toastify__toast-body .Toastify__toast-icon {
  padding: 8px;
  background: #c2c7f7;
  width: 32px;
  height: 32px;
  font-size: 11px;
  border-radius: 50%;
} */

.Toastify__toast--info > .Toastify__toast-body .Toastify__toast-icon > svg {
  fill: #384ae7;
}

.Toastify__toast--info > .Toastify__toast-body::before {
  width: 100%;
}

.Toastify__close-button--info > svg {
  fill: #384ae7 !important;
}

.Toastify__close-button--info {
  margin: auto 0;
  padding-right: 15px !important;
}

.toast-blank-info {
  background-color: white !important;
  border: 0px;
  color: #132b40 !important;
}

.toast-blank-info::before {
  content: "";
  width: 6px;
  background: #1e22ce !important;
  margin-right: 6px;
}

#tooltip-top > .tooltip-inner {
  background-color: #132b40;
  border: 1px solid #062e56;
  border-radius: 8px;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

#tooltip-top > .tooltip-arrow {
  border-top: 5px solid #132b40;
}

.custom-radio > .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--call-in-action);
  background-color: #fff;
  border-width: 2px;
}

.custom-radio > .custom-control-input:checked ~ .custom-control-label::after {
  height: 8px;
  width: 8px;
  background-color: var(--call-in-action);
  border-radius: 50%;
  align-items: center;
  margin: 4px;
}

.snackbar-design {
  background-color: #12167c !important;
  border-color: #12167c;
  color: #fff !important;
}
.snackbar-design > .Toastify__close-button > svg {
  fill: #ffff !important;
}
.snackbar-design > .Toastify__toast-body > .Toastify__toast-icon > svg {
  fill: #ffff !important;
}
