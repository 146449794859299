.badgeFilter {
  background-color: #edf1f7;
  border-radius: 16px;
  cursor: default;
  margin-right: 4px;
  text-transform: capitalize;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 6px;
  font-size: 13px;
  margin-top: 5px;
  color: #2a2b2e;
  display: flex;
  align-items: center;
}

.badgeIcon {
  font-size: 20px;
  cursor: pointer;
  color: rgba(120, 124, 131, 0.5);
}
