@import "./../../App.css";

.wrapper {
  display: block;
}

#sidebar {
  background: var(--brand-color);
  color: var(--color-surface);
  min-width: 224px;
  max-width: 224px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.5s;
}

/* 
.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
  opacity: 0;
}

.overlay.active {
  display: block;
  opacity: 1;
  position: fixed;
  top: 0;
} */

#dismiss {
  margin-top: -6px;
}

.buttonStyle {
  cursor: pointer;
}

#sidebar.active {
  margin-left: -224px;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

#sidebar .sidebar-header {
  display: flex;
  align-items: center;
}

#sidebar .sidebar-content {
  margin-top: 50px;
}

#sidebar ul.components {
  margin-top: 20px;
}

#sidebar ul p,
#sidebar ul a {
  color: var(--color-surface) !important;
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-md);
  line-height: 17px;
}

#sidebar ul p.active {
  font-weight: bold;
}

#sidebar ul li {
  padding-left: 16px;
  margin-bottom: 16px;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

.sidebar-col {
  padding: 30px 25px;
}

.sidebar-title {
  font-weight: 500;
}
