.noteBlock {
  background: var(--color-aqua);
  padding: 0 10px;
}

.noteBlock:nth-child(1) {
  padding-top: 10px;
}

.noteEntry {
  background: var(--color-white);
  border: 1px solid var(--color-aqua);
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 16px 18px;
}

.noteInfo {
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 13px;
}

.noteInfo span {
  color: var(--secondary-color);
}

.noteContainer {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dropDownContainer {
  margin-top: 25px;
  width: 100%;
}

.dropDown select {
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.dropDown {
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dropDown::after {
  content: "▼";
  font-size: 10px;
  right: 15px;
  position: absolute;
  top: 40%;
}
