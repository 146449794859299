/*
 * Nimbi Theme
 * Author: Nimbi
 * https://www.nimbi.com
 */

@import _variables
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap')

$base-font-family: 'Roboto', sans-serif
$base-gray: (one: #eeeeee, two: #f5f5f5, three: #565656, four: #666a6f, five: #e6e6e6, six: #0f485c, seven: #dcdfe1)

body
    font-family: $base-font-family

.modal-background
    background-color: rgba(10, 10, 10, 0.66)

.n-text-secondary
    color: $color-text-secondary

.form-control
    font-size: 0.875rem
