// nimbi-theme custom variables

$brand-color: #cc0200
$color-surface: #f8f9fc
$color-text-primary: #455362
$color-text-secondary: #7c8a99
$color-bg-primary: #cc0200
$color-bg-secondary: #f8f9fc
$custom-control-indicator-border-color: #9CABC2
$custom-radio-indicator-icon-checked-color: transparent
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-radio-indicator-icon-checked-color}'/></svg>")