@import "../../App.css";

.modalProfile .titleHeader {
  color: var(--color-text-primary);
  font-size: 18px;
}

.modalProfile .footerContainer {
  color: var(--color-gray);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.modalProfile .bodyContainer {
  color: var(--color-gray);
  min-height: 550px;
  padding-bottom: 40px;
  background-color: var(--color-background-secundary);
}

.modalProfile .bodyBorderRadius {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.modalProfile .studentTitle {
  text-decoration: underline;
}

.shadow:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.3);
}

.coursesBlock {
  max-height: 555px;
  overflow: auto;
}

.coursesBlock .courseHeader {
  padding: 10px 20px;
}

.coursesBlock .rowCourse {
  border: 1px solid var(--color-aqua);
  border-radius: 6px;
  padding: 10px 20px;
  margin-top: 10px;
}

.callOverdueBlock {
  color: var(--brand-color);
  font-size: var(--font-size-small);
  margin-top: 10px;
}

.headerTitle {
  color: var(--color-text-secondary);
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
}

.tabContainer .fieldBox2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--color-dark-aqua);
  border-radius: 6px;
  color: var(--color-text-primary);
  padding: 4px 8px;
}

.tabContainer .title1 {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
}

.tabContainer .label2 {
  color: var(--color-text-primary);
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  text-transform: capitalize;
}

.tabContainer .iconField {
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.tabContainer .radiosContainer {
  color: var(--color-text-primary);
  height: 40px;
}

.tabContainer .contactBlock {
  background: var(--color-bg-secundary);
  border-radius: 6px;
  color: var(--color-text-primary);
  min-height: 150px;
  max-height: 275px;
  overflow: auto;
  padding: 15px 20px;
}

.tabContainer .fieldBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-bg-secundary);
  border-radius: 6px;
  color: (var--color-text-secondary);
  padding: 8px 12px;
}

.tabContainer .emailRow {
  border-top: 1px solid var(--color-aqua);
  border-bottom: 1px solid var(--color-aqua);
  padding: 20px 0;
}

.fieldBox2 .inputInfo {
  border: none;
  color: var(--color-text-primary);
  outline: none;
  width: 89%;
}

.addGroup {
  float: right;
}

.addGroup .dropdownBtnMenu {
  min-width: 186px;
}

.addGroup .AddBtn {
  white-space: pre;
  font-size: 0.9rem;
  margin-bottom: 4px;
}

/* .addGroup .addIcon {
  color: var(--brand-color);
} */

.tabHistory .tabContent {
  max-height: 555px;
  overflow: auto;
}
