@import "../../App.css";

.callingActionBlock {
  background: var(--color-white);
  border: 0;
}

.callingActionBlock p {
  display: inline;
  font-size: 13px;
  font-weight: normal;
}

.callingSaveButton {
  float: right;
  font-size: var(--font-size-md);
  padding: 8px 28px;
}

.lightBulbIcon {
  color: var(--color-white);
  margin-left: 1px;
  margin-top: 5px;
}

.circle {
  background-color: var(--color-orange);
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  width: 25px;
}

.phoneContactBlock {
  background: var(--color-white);
  border: 1px solid #dee0e7;
  border-radius: 6px;
  padding: 10px;
}

.callActionTitle {
  font-size: 18px;
}

.callingActionReasonBlock {
  background: var(--color-aqua);
  border-radius: 6px;
  padding: 12px;
}

.callingActionHeader {
  border-bottom: 2px solid var(--color-aqua);
  border-top: 2px solid var(--color-aqua);
  padding: 8px 0;
}

.copyEmailAddress {
  cursor: pointer;
}
