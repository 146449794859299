.noTasks .fadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
}

.skeleton {
  min-height: 1000px;
}

.locked {
  pointer-events: none;
  opacity: 0.8;
  background: var(--color-dark-aqua);
  border-radius: 6px;
}

.customBox {
  background: var(--color-white);
  border-radius: 6px;
}

.headerTitle {
  color: var(--color-text-secondary);
  cursor: default;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}

.rowText {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.moduleTitle {
  font-size: 1.3em;
  font-weight: 700;
}

.titleUserAction {
  font-size: 1.7em;
  font-weight: 700;
}

.filterContainer {
  background: var(--color-white);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 0;
}

.linkAction {
  cursor: pointer;
  font-weight: 700;
}

.linkAction:hover {
  text-decoration: underline;
}

.footerUserForm {
  border-top: 2px solid var(--color-dark-aqua);
}

.badgeText {
  text-transform: none !important;
}
.iconField {
  cursor: pointer;
}

.contactThumb {
  height: 32px;
  width: 32px;
}

.listUserThumb {
  height: 42px;
  width: 42px;
}
