.mainContent .actionMessage {
  font-size: 1.15;
  padding: 0 0 20px 0;
}

.mainContent input[type="checkbox"] {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
}

.mainContent .confirmButton {
  float: right;
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
}

.mainContent .confirmButton:hover {
  filter: brightness(85%);
}
