@import "../../App.css";

.widgetRow {
  /* background: var(--color-aqua); */
}

.widgetRow .lastUpload {
  color: var(--color-text-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-caption);
  line-height: 160%;
}

.widgetContent {
  display: -webkit-inline-box;
  overflow: auto;
  padding: 10px 10px 30px 10px;
}

.widgetItem {
  background: var(--color-white);
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid var(--color-dark-aqua);
  border-radius: 6px;
  margin-right: 20px;
  min-height: 150px;
  width: 280px;
}

.widgetItem .spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.widgetItem:last-child {
  margin-right: 0;
}

.widgetItem .itemTotals {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.widgetItem .counter {
  color: var(--color-text-primary);
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 58px;
}

.widgetItem .score {
  color: var(--color-text-primary);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1px;
}

.widgetItem .itemDesc1 {
  color: var(--color-text-primary);
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: var(--font-size-md);
  line-height: 17px;
}

.widgetItem .itemDesc2 {
  color: var(--color-text-primary);
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-caption);
  line-height: 13px;
}

.filterTitle {
  cursor: default;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-text-primary);
}

.contactThumb {
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.customBox {
  background: var(--color-white);
  border-radius: 6px;
}

.contactPopover,
.momentPopover {
  width: 200px;
}

.contactPopover .title {
  color: var(--color-text-primary);
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
}

.contactPopover .dateInfo {
  color: var(--color-text-secondary);
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 13px;
}

.contactPopover .contactUser {
  color: var(--color-text-secondary);
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  padding: 10px 0;
}

.contactPopover .historyLink {
  border-top: 1px solid var(--color-dark-aqua);
  color: var(--color-text-primary);
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  padding: 10px 16px;
}

.notAssigned {
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--secondary-color);
}

.firstColumn {
  padding: 14px 8px;
}

.headerTitle {
  color: var(--color-text-secondary);
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}

.callOverdueBlock {
  color: var(--brand-color);
  font-size: var(--font-size-small);
  margin-top: 10px;
}

.rowText {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.rowItem .actionCol {
  display: flex;
  justify-content: center;
}

.rowItem .actionColContent {
  min-height: 44px;
  margin-top: 24px;
}

.circleActionButton {
  background: var(--call-in-action);
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
}

.circleActionButton:hover {
  background: var(--call-in-action);
  filter: brightness(90%);
}

.callActionTitle {
  font-size: 18px;
}

.callingActionReasonBlock {
  background: var(--color-aqua);
  border-radius: 6px;
  padding: 12px;
}

.circle {
  background-color: var(--validation-color);
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  width: 25px;
}

.lightBulbIcon {
  color: var(--color-white);
  margin-left: 1px;
  margin-top: 5px;
}

.copyPhoneNumber {
  cursor: pointer;
}

.phoneContactBlock {
  background: var(--color-white);
  border: 1px solid #dee0e7;
  border-radius: 6px;
  padding: 10px;
}

.callingSaveButton {
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
  float: right;
  font-size: var(--font-size-md);
  padding: 8px 28px;
}

.callingSaveButton:hover {
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
  filter: brightness(90%);
}

.actionBlockContent .tabProfileContent {
  color: var(--color-gray);
  height: 610px;
  padding-bottom: 40px;
}
