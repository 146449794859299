@import "../../App.css";

.messageBLock {
  background: var(--color-white);
  border: 1px solid var(--color-aqua);
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 30px;
  padding: 16px 18px;
}

.messageBLock p {
  margin-bottom: 0.5rem;
}

.messageBLock p:last-child {
  margin-bottom: 0;
}

.messageBLock .rightBlock {
  text-align: right;
}

.messageBLock .leftBlock {
  text-align: left;
}

.messageBLock .subject {
  color: var(--color-text-primary);
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  font-style: italic;
}

.messageBLock .description {
  color: var(--color-text-primary);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
}

.messageBLock .dateInfo {
  color: var(--color-gray);
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 13px;
}

.container .iconStyle {
  margin-left: -28px;
  position: absolute;
  margin-top: -32px;
}

.container .bgDark {
  background: var(--color-bg-secundary);
}
