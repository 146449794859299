.loginBlock {
  height: 100%;
}

.recoveryLink {
  float: right;
  color: #3384ff !important;
  font-weight: 500;
}

.textLink {
  color: #3384ff !important;
  font-weight: 500;
}

.textLink:hover {
  text-decoration: none;
}

.recoveryLink:hover {
  text-decoration: none;
}

.loginLink {
  padding-top: 10px;
  text-align: center;
  display: block;
}

.azure_ad_login {
  background-color: #0178d4;
  border-color: #0178d4;
}

.azure_ad_login:hover,
.azure_ad_login:focus {
  background-color: #106ebe;
  border-color: #106ebe;
}
