@import "../../App.css";

.navBlock {
  -webkit-box-shadow: 0px 3px 16px rgba(143, 164, 189, 0.25);
  -moz-box-shadow: 0px 3px 16px rgba(143, 164, 189, 0.25);
  box-shadow: 0px 3px 16px rgba(143, 164, 189, 0.25);
  padding: 1em 1.2em;
  height: 86px;
}

.mainTitle {
  color: var(--color-text-secondary);
  cursor: pointer;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
}

.sessionName {
  color: var(--color-text-secondary);
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
}

.sessionJob {
  color: var(--color-text-secondary);
  cursor: pointer;
  font-size: 13px;
  line-height: 14px;
}

.thumb {
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: var(--color-bg-secundary);
  height: 48px;
  margin-left: 10px;
  width: 48px;
}

.momentsContainer {
  display: flex;
}

.momentItem:hover,
.momentItem a:hover {
  color: var(--color-text-primary);
}

.wborder {
  border-right: 3px solid var(--color-aqua);
}

.momentItem a {
  color: var(--color-text-primary);
  text-decoration: none;
}

.momentItem .momentTitle {
  cursor: pointer;
  font-weight: 400;
  font-size: var(--font-size-md);
}

.momentItem .momentSubTitle {
  cursor: pointer;
  font-size: var(--font-size-md);
  font-weight: 400;
}

.momentItem .selected {
  color: var(--brand-color);
  font-weight: 700;
}

.toolbarBlock {
  padding: 0;
}

.toolbarBlock .lastCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.toolbarBlock .btnToggleFilters {
  border: 1px solid var(--color-surface);
  cursor: default;
  float: right;
  font-weight: 700;
  padding: 5px 10px;
  position: absolute;
  top: -34px;
  right: 100px;
}

.toolbarBlock .filterContainer {
  padding: 10px 4px 2px 4px;
}

.filterBlock .filterTitle {
  color: var(--color-text-secondary);
  margin-left: 0;
}

.filterBlock .filterButton {
  background-color: var(--call-in-action);
  color: white;
  font-size: 0.8em;
  max-width: 220px;
}

.toolbarBlock .btnApplyFilters {
  position: absolute;
  right: 20px;
  top: -33px;
}

.tabContainer .filterTab {
  padding: 40px 0;
}

.tabContainer .searchTab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.tabContainer .filterBlock {
  background: var(--color-white);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 0;
}

.searchTab .label {
  cursor: default;
  font-size: 1.1em;
}

.boxBorder {
  padding: 6px;
  background: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-dark-aqua);
}

.boxBorder .active {
  background: var(--color-gray);
  color: var(--color-white);
  cursor: pointer;
}

.customBadge {
  background: var(--color-surface);
  border: 1px solid var(--color-dark-aqua);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 13px;
  text-align: center;
  text-transform: capitalize;
  padding: 2px 4px;
}

.customBoxHeader {
  border-radius: 6px;
}

.customBox {
  background: var(--color-white);
  border-radius: 6px;
}

.locked {
  pointer-events: none;
  opacity: 0.8;
  background: var(--color-dark-aqua);
  border-radius: 6px;
}

.firstColumn {
  padding: 14px 8px;
}

.headerTitle {
  color: var(--color-text-secondary);
  cursor: default;
  font-style: normal;
  font-size: var(--font-size-md);
  line-height: 16px;
}

.rowText {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.barsButton {
  cursor: pointer;
  color: var(--brand-color);
}

.callOverdueBlock {
  /* color: var(--brand-color); */
  font-size: var(--font-size-small);
  margin-top: 5px;
}

.notAssigned {
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 16px;
  text-transform: uppercase;
  /* color: var(--validation-color); */
  color: var(--secondary-color);
}

.expirationMessage {
  font-style: normal;
  font-weight: bold;
  font-size: var(--font-size-caption);
  line-height: 16px;
  text-transform: uppercase;
  /* color: var(--validation-color); */
  color: var(--secondary-color);
}

.circleActionButton {
  background: var(--call-in-action);
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
}

.circleActionButton:hover {
  background: var(--call-in-action);
  filter: brightness(90%);
}

.profileMenu {
  color: var(--color-gray);
  width: 20%;
}

.profileMenu .menuIcon {
  color: var(--color-gray);
  font-size: 13px;
}

.modalHistory .titleHeader {
  color: var(--color-text-primary);
  font-size: 18px;
}

.modalHistory .footerContainer {
  color: var(--color-gray);
  font-weight: bold;
}

.modalHistory .bodyContainer {
  color: var(--color-gray);
  height: 460px;
}

.modalHistory .studentTitle {
  text-decoration: underline;
}

.excelButton {
  background: var(--color-white);
  border: 1px solid var(--color-dark-aqua);
  border-radius: 6px;
  cursor: pointer;
  padding: 4px 14px;
}

.messageBodyHistory {
  max-height: 460px;
  overflow: auto;
}

.messageTitleSelect {
  position: absolute;
  right: 75px;
}

.contactThumb {
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.contactPopover,
.momentPopover {
  width: 200px;
}

.contactPopover .title {
  color: var(--color-text-primary);
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
}

.contactPopover .dateInfo {
  color: var(--color-text-secondary);
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 13px;
}

.contactPopover .contactUser {
  color: var(--color-text-secondary);
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  padding: 10px 0;
}

.contactPopover .historyLink {
  border-top: 1px solid var(--color-dark-aqua);
  color: var(--color-text-primary);
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  padding: 10px 16px;
}

.momentPopover .titleBlock {
  background-color: var(--color-bg-secundary);
  text-align: center;
}

.momentPopover .titleText {
  color: var(--color-text-primary);
  font-size: 13px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .messageTitleSelect {
    margin-top: 12px;
    position: relative;
    right: auto;
  }

  .msgModalHeader {
    flex-direction: column;
  }

  .msgModalHeader > button[class="close"] {
    position: absolute;
    right: 18px;
  }

  .profileMenu {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.groupedBlock {
  background: var(--color-aqua);
  /* border: 5px solid var(--color-aqua); */
  border-radius: 6px;
  padding: 0;
}

.groupedBlock .headerBlock {
  margin-top: 10px;
}

.studentName {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-text-primary);
}

.closeGrouped {
  color: var(--color-orange);
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 10px;
}

.accordionContent {
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  overflow: hidden;
  max-height: 999px;
  /* visibility: visible; */
  margin-bottom: 30px;
}
.hideAccordionContent {
  -webkit-transition: max-height 0.9s;
  -moz-transition: max-height 0.9s;
  -ms-transition: max-height 0.9s;
  -o-transition: max-height 0.9s;
  transition: max-height 0.9s;

  overflow: hidden;
  max-height: 0;
  /* visibility: hidden; */
  margin-bottom: 0;
}

.separatorBar {
  border-right: 4px solid var(--color-aqua);
  position: absolute;
  height: 100%;
  left: 49%;
}

.colSeparator {
  max-width: 9%;
  padding: 0;
}

.separatorBar .iconSep {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3ac8ac;
  /* var(--secondary-color); */
  border-radius: 50%;
  height: 54px;
  margin-left: -26px;
  margin-top: 30px;
  position: absolute;
  width: 54px;
}

.separatorBar .iconSep .iconSepBorder {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  /* background: var(--secondary-color); */
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.separatorBar .iconSep .iconSepCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #edf1f7, #edf1f7), #ffffff;
  /* background: var(--secondary-color); */
  border-radius: 50%;
  height: 42px;
  width: 42px;
}

.addPaddingX {
  padding: 0 20px;
}

.noTasks .fadeIn,
.toolbarBlock .fadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.noTasks .fadeOut,
.toolbarBlock .fadeOut {
  /* opacity: 1; */
  animation: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.4s;
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.skeleton {
  min-height: 1000px;
}

.rowItem .actionCol {
  display: flex;
  justify-content: center;
}

.rowItem .actionColContent {
  min-height: 44px;
  margin-top: 24px;
}

.historyItem {
  width: 100%;
}

.historyItem:last-child {
  padding-bottom: 60px;
}

.callActionTitle {
  font-size: 18px;
}

.callingActionReasonBlock {
  background: var(--color-aqua);
  border-radius: 6px;
  padding: 12px;
}

.circle {
  background-color: var(--secondary-color);
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  width: 25px;
}

.lightBulbIcon {
  color: var(--color-white);
  margin-left: 1px;
  margin-top: 5px;
}

.copyPhoneNumber {
  cursor: pointer;
}

.phoneContactBlock {
  background: var(--color-white);
  border: 1px solid #dee0e7;
  border-radius: 6px;
  padding: 10px;
}
