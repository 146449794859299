@import "../../App.css";

.colGroupTitle {
  border-bottom: 3px solid var(--color-aqua);
}

.colGroupTitle h4 {
  color: var(--color-text-primary);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.reportCard {
  width: 100%;
}

.reportCard .reportTitle {
  color: var(--color-text-primary);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-md);
  line-height: 17px;
}

.reportCard .reportDesc {
  color: var(--color-text-primary);
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-caption);
  line-height: 150%;
}

.reportLink {
  font-size: 1em;
  float: right;
  color: var(--call-in-action);
}

.reportLink:hover {
  color: var(--call-in-action);
}

.breadcrumb {
  color: var(--color-gray);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 160%;
}

.breadcrumb a:any-link {
  color: var(--color-gray);
}

.breadcrumb .reportTitle {
  font-weight: bold;
  cursor: default;
}

.reportView {
  color: var(--color-text-primary);
  font-style: normal;
}

.reportView .wbTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.reportView .wbtDesc {
  font-weight: normal;
  font-size: var(--font-size-small);
  line-height: 150%;
}
