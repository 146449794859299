.filterBody {
  display: flex;
  flex-direction: row;
}

.filterImgContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--brand-color);
}
