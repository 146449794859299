@import "../../App.css";

.toggleButton,
.toggleButton.disabled:hover {
  background-color: var(--call-in-action) !important;
  border-color: var(--call-in-action) !important;
}

.toggleButton:not(.disabled):hover {
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
  filter: brightness(85%);
}
