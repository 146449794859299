.callingSaveButton {
  float: right;
  font-size: var(--font-size-md);
  padding: 8px 28px;
  background-color: var(--call-in-action);
  border-color: var(--call-in-action);
  color: white;
}

.callingSaveButton:hover {
  color: white;
  filter: brightness(90%);
}

.disableEffect {
  filter: brightness(90%);
  pointer-events: none;
}
