.profileMenu .menuIcon {
  color: var(--color-gray);
  font-size: 13px;
}

.modalHistory .titleHeader {
  color: var(--color-text-primary);
  font-size: 18px;
}

.modalHistory .footerContainer {
  color: var(--color-gray);
  font-weight: bold;
}
