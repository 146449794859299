@import "../../App.css";

.blockContent {
  border: 1px solid var(--color-dark-aqua);
  border-radius: 4px;
  padding: 18px 20px;
}

.blockContent .title {
  background-color: var(--color-bg-secundary);
  display: table;
  font-weight: 700;
  padding: 0 10px;
  position: absolute;
  margin-top: -30px;
}
