@import "./../../App.css";

.scriptContainer {
  background: var(--color-white);
  box-shadow: 0px 3px 16px rgba(143, 164, 189, 0.25);
  height: 100vh;
  min-width: 498px;
  max-width: 498px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  transition: all 0.5s;
}

.scriptContainer .header {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  padding: 24px;
}

.header .closeButton {
  cursor: pointer;
}

.content .body {
  border-top: 1px solid var(--color-aqua);
  padding: 24px;
  max-height: 90%;
  overflow: auto;
}

#sidebarscript.active {
  margin-right: -498px;
}

.header .scriptSelect {
  max-width: 200px;
}
